import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store/store';

Vue.config.productionTip = false

// axios
import axios from 'axios'
Vue.prototype.$http = axios
Vue.prototype.CancelToken = axios.CancelToken;

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// Session
import VueSession from 'vue-session'
var options = {
  persist: true
}
Vue.use(VueSession, options)

// vue-json-excel

import JsonExcel from 'vue-json-excel'
Vue.component("JsonExcel", JsonExcel);

// v-viewer

// import 'viewerjs/dist/viewer.css'
// import VueViewer from 'v-viewer'

// v-mask
import VueMask from 'v-mask'
Vue.use(VueMask)


// For Custom Layout components
import DefaultLayout from "@/layouts/DefaultLayout.vue";
Vue.component("default-layout", DefaultLayout);

import BlankLayout from "@/layouts/BlankLayout.vue";
Vue.component("blank-layout", BlankLayout);

import VuetifyAudio from 'vuetify-audio';
Vue.component("vuetify-audio", VuetifyAudio);

import BasicVueChat from 'basic-vue-chat';
Vue.component("basic-vue-chat", BasicVueChat);

import DemoMode from "@/DemoMode.vue";
Vue.component("demo-mode", DemoMode);

// Vuex Store

import Vuex from 'vuex';
Vue.use(Vuex);

// Internationalization

import i18n from './i18n/i18n'

// Vue Loading Overlay

import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import {Howl, Howler} from 'howler';


Vue.use(VueLoading, {
  "loader": "dots",
  "color": "#FF9F43",
  "background-color": "#d3ffce",
  "opacity": 0.7
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
