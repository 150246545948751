import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "login",
  },
  {
    path: "/login",
    name: "login",
    meta: { layout: "blank" },
    component: () => import("@/views/LoginPage.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/MyDashboard.vue"),
  },

  // Branch

  {
    path: "/branch/list",
    name: "Branch List",
    component: () => import("@/views/branch/BranchList.vue"),
  },
  {
    path: "/branch/create",
    name: "Add New Branch",
    component: () => import("@/views/branch/BranchCreate.vue"),
  },
  {
    path: "/branch/edit",
    name: "Edit Branch",
    component: () => import("@/views/branch/BranchEdit.vue"),
  },

  // party

  {
    path: "/party/list",
    name: "Party List",
    component: () => import("@/views/party/PartyList.vue"),
  },

  // chat

  {
    path: "/chat/new",
    name: "New Chat",
    component: () => import("@/views/chat/NewChat.vue"),
  },
  {
    path: "/chat/customer",
    name: "Customer Chat",
    component: () => import("@/views/chat/CustomerChat.vue"),
  },
  {
    path: "/customer/edit",
    name: "Customer Edit",
    component: () => import("@/views/chat/CustomerEdit.vue"),
  },

  //ledger

  {
    path: "/ledger/list",
    name: "Ledger List",
    component: () => import("@/views/ledger/LedgerList.vue"),
  },
  {
    path: "/ledger/create",
    name: "Ledger Create",
    component: () => import("@/views/ledger/LedgerCreate.vue"),
  },
  {
    path: "/ledger/edit",
    name: "Ledger Edit",
    component: () => import("@/views/ledger/LedgerEdit.vue"),
  },

  //ledgergroup

  {
    path: "/ledger-group/list",
    name: "Ledger Group List",
    component: () => import("@/views/ledger/group/LedgerGroupList.vue"),
  },
  {
    path: "/ledger-group/create",
    name: "Ledger Group Create",
    component: () => import("@/views/ledger/group/LedgerGroupCreate.vue"),
  },
  {
    path: "/ledger-group/edit",
    name: "Ledger Group Edit",
    component: () => import("@/views/ledger/group/LedgerGroupEdit.vue"),
  },

  //ledgerbranchmap

  {
    path: "/ledger-branchmap/list",
    name: "Ledger BranchMap List",
    component: () => import("@/views/ledger/branchmap/LedgerBranchMapList.vue"),
  },
  {
    path: "/ledger-branchmap/create",
    name: "Ledger BranchMap Create",
    component: () =>
      import("@/views/ledger/branchmap/LedgerBranchMapCreate.vue"),
  },
  {
    path: "/ledger-branchmap/edit",
    name: "Ledger BranchMap edit",
    component: () => import("@/views/ledger/branchmap/LedgerBranchMapEdit.vue"),
  },

  //partygroup

  {
    path: "/party-group/list",
    name: "Party Group List",
    component: () => import("@/views/party/groups/PartyGroupList.vue"),
  },

  //partytype

  {
    path: "/party-type/list",
    name: "Party Type List",
    component: () => import("@/views/party/type/PartyTypeList.vue"),
  },
  {
    path: "/party-type/create",
    name: "Party Type Create",
    component: () => import("@/views/party/type/PartyTypeCreate.vue"),
  },
  {
    path: "/party-type/edit",
    name: "Party Type Edit",
    component: () => import("@/views/party/type/PartyTypeEdit.vue"),
  },

  //partycustomer

  {
    path: "/party-customer/list",
    name: "Party Customer List",
    component: () => import("@/views/party/customer/PartyCustomerList.vue"),
  },
  {
    path: "/party-customer/create",
    name: "Party Customer Create",
    component: () => import("@/views/party/customer/PartyCustomerCreate.vue"),
  },

  //stranger details
  {
    path: "/stranger/list",
    name: "Stranger List",
    component: () => import("@/views/party/strangers/StrangerList.vue"),
  },

  // product group details

  {
    path: "/product-group/list",
    name: "Product Group List",
    component: () => import("@/views/product/group/ProductGroupList.vue"),
  },

  //price group details

  {
    path: "/price-group/list",
    name: "Price Group List",
    component: () => import("@/views/product/pricegroup/PriceGroupList.vue"),
  },

  //product details

  {
    path: "/product/list",
    name: "Product List",
    component: () => import("@/views/product/ProductList.vue"),
  },
  {
    path: "/product/create",
    name: "Product Create",
    component: () => import("@/views/product/ProductCreate.vue"),
  },
  {
    path: "/product/edit",
    name: "Product Edit",
    component: () => import("@/views/product/ProductEdit.vue"),
  },

  //album details

  {
    path: "/album/list",
    name: "Album List",
    component: () => import("@/views/album/AlbumList.vue"),
  },

  //album payment details

  {
    path: "/album-payment/list",
    name: "Album Payment List",
    component: () => import("@/views/album/payment/AlbumPaymentList.vue"),
  },

  //album payment details

  {
    path: "/album-sales/list",
    name: "Album Sales List",
    component: () => import("@/views/album/sales/AlbumSalesList.vue"),
  },
  {
    path: "/album-sales/create",
    name: "Album Sales Create",
    component: () => import("@/views/album/sales/AlbumSalesCreate.vue"),
  },

  //album branchmap details

  {
    path: "/album-branchmap/list",
    name: "Album Branchmap List",
    component: () => import("@/views/album/branchmap/AlbumBranchmapList.vue"),
  },

  {
    path: "/album-not-given/Customer-list",
    name: "Album Not Given Customer List",
    component: () => import("@/views/album/AlbumNotGivenCustomerList.vue"),
  },

  {
    path: "/jet-21/list",
    name: "Jet 21 List",
    component: () => import("@/views/album/Jet21List.vue"),
  },

  //transaction receipt details

  {
    path: "/receipt/list",
    name: "Receipt List",
    component: () => import("@/views/transaction/receipt/ReceiptList.vue"),
  },

  //transaction payment details

  {
    path: "/payment/list",
    name: "Payment List",
    component: () => import("@/views/transaction/payment/PaymentList.vue"),
  },

  //transaction Contra details

  {
    path: "/contra/list",
    name: "Contra List",
    component: () => import("@/views/transaction/contra/ContraList.vue"),
  },

  //transaction journal details

  {
    path: "/journal/list",
    name: "Journal List",
    component: () => import("@/views/transaction/journal/JournalList.vue"),
  },

  //transaction purchase transaction details

  {
    path: "/purchase/list",
    name: "Purchase List",
    component: () => import("@/views/transaction/purchase/PurchaseList.vue"),
  },

  //transaction details

  {
    path: "/transaction/list",
    name: "Transaction List",
    component: () => import("@/views/transaction/TransactionList.vue"),
  },

  //user details

  {
    path: "/users/list",
    name: "Users List",
    component: () => import("@/views/users/UsersList.vue"),
  },

  //Change password

  {
    path: "/change/password",
    name: "Change Password",
    component: () => import("@/views/ChangePassword.vue"),
  },

  //menu permission

  {
    path: "/menu-permission/lists",
    name: "Menu Permission Lists",
    component: () => import("@/views/menu/MenuPermissionList.vue"),
  },

  //product refill report

  {
    path: "/product/refill/report",
    name: "Product Refill Report",
    component: () => import("@/views/reports/ProductRefillReport.vue"),
  },
  // purchase order
  {
    path: "/purchase/order/lists",
    name: "Purchase Order Lists",
    component: () => import("@/views/purchase/PurchaseOrderList.vue"),
  },
  {
    path: "/purchase/order/create",
    name: "Purchase Order Create",
    component: () => import("@/views/purchase/PurchaseOrderCreate.vue"),
  },
  // inward details
  {
    path: "/inward/details/lists",
    name: "Inward Details Lists",
    component: () => import("@/views/purchase/inward/InwardDetailsList.vue"),
  },
  {
    path: "/add/inward/details",
    name: "Add Inward Details",
    component: () => import("@/views/purchase/inward/AddInwardEntry.vue"),
  },
  // chat list
  {
    path: "/chat/lists",
    name: "Chat Lists",
    component: () => import("@/views/chat/ChatList.vue"),
  },
  // goods return
  {
    path: "/goods/return/lists",
    name: "Goods Return Lists",
    component: () => import("@/views/purchase/goodsreturn/GoodsReturnList.vue"),
  },
  {
    path: "/goods/return/create",
    name: "Goods Return Create",
    component: () =>
      import("@/views/purchase/goodsreturn/GoodsReturnCreate.vue"),
  },
  // sales order
  {
    path: "/sales/order/lists",
    name: "Sales Order Lists",
    component: () => import("@/views/sales/order/SalesOrderList.vue"),
  },
  {
    path: "/sales/order/create",
    name: "Sales Order Create",
    component: () => import("@/views/sales/order/SalesOrderCreate.vue"),
  },
  // sales shortage
  {
    path: "/sales/shortage/lists",
    name: "Sales Shortage Lists",
    component: () => import("@/views/sales/shortage/SalesShortageList.vue"),
  },
  {
    path: "/sales/shortage/create",
    name: "Sales Shortage Create",
    component: () => import("@/views/sales/shortage/SalesShortageCreate.vue"),
  },
  // product branchmap

  {
    path: "/product/branchmap/lists",
    name: "Product BranchMap List",
    component: () =>
      import("@/views/product/branchmap/ProductBranchMapList.vue"),
  },
  {
    path: "/product/branchmap/create",
    name: "Product BranchMap Create",
    component: () =>
      import("@/views/product/branchmap/ProductBranchMapCreate.vue"),
  },
  {
    path: "/product/branchmap/edit",
    name: "Product BranchMap Edit",
    component: () =>
      import("@/views/product/branchmap/ProductBranchMapEdit.vue"),
  },
  // sales list
  {
    path: "/sales/lists",
    name: "Sales Lists",
    component: () => import("@/views/sales/SalesList.vue"),
  },
  // product group branch map
  {
    path: "/product/group/branchmap/lists",
    name: "Product Group BranchMap List",
    component: () =>
      import("@/views/product/group/branchmap/ProductGroupBranchmapList.vue"),
  },
  {
    path: "/product/group/branchmap/create",
    name: "Product Group BranchMap Create",
    component: () =>
      import("@/views/product/group/branchmap/ProductGroupBranchmapCreate.vue"),
  },
  {
    path: "/product/group/branchmap/Edit",
    name: "Product Group BranchMap Edit",
    component: () =>
      import("@/views/product/group/branchmap/ProductGroupBranchmapEdit.vue"),
  },
  // product price group branch map
  {
    path: "/product/price/branchmap/lists",
    name: "Price Group BranchMap List",
    component: () =>
      import(
        "@/views/product/pricegroup/branchmap/PriceGroupBranchMapList.vue"
      ),
  },
  {
    path: "/product/price/branchmap/create",
    name: "Price Group BranchMap Create",
    component: () =>
      import(
        "@/views/product/pricegroup/branchmap/PriceGroupBranchMapCreate.vue"
      ),
  },
  {
    path: "/product/price/branchmap/edit",
    name: "Price Group BranchMap Edit",
    component: () =>
      import(
        "@/views/product/pricegroup/branchmap/PriceGroupBranchMapEdit.vue"
      ),
  },
  // product group map
  {
    path: "/product/groupmap/list",
    name: "Product GroupMap List",
    component: () =>
      import("@/views/party/groupmap/PartyProductGroupMapList.vue"),
  },
  {
    path: "/product/groupmap/create",
    name: "Product GroupMap Create",
    component: () =>
      import("@/views/party/groupmap/PartyProductGroupMapCreate.vue"),
  },
  {
    path: "/product/groupmap/edit",
    name: "Product GroupMap Edit",
    component: () =>
      import("@/views/party/groupmap/PartyProductGroupMapEdit.vue"),
  },
  {
    path: "/chat/history/delete",
    name: "Chat History Delete",
    component: () => import("@/views/chat/ChatHistoryDelete.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log({ to });
    console.log({ from });
    console.log({ savedPosition });
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  var ToName = to.name;
  console.log({ ToName });

  var fromPath = from.path;
  var toPath = to.path;
  console.log("navigation from=" + fromPath + ", to=" + toPath);

  var token = localStorage.getItem("token");
  token = token == (null || undefined) ? "" : token;
  console.log({ token });

  if (to.path == "/" || to.path == "/login" || to.path == "/*") {
    console.log("navigation true");
    next();
  } else {
    console.log("navigation false");
    if (token != "") {
      console.log("token is valid");
      next();
    } else {
      console.log("session is expired");
      router.push("/login");
    }
  }
});

export default router;
